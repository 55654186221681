@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/styles/fonts.css */

/* RedHatDisplay-Black */
@font-face {
  font-family: "RedHatDisplay-Black";
  src: url("./fonts/static/RedHatDisplay-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* RedHatDisplay-BlackItalic */
@font-face {
  font-family: "RedHatDisplay-BlackItalic";
  src: url("./fonts/static/RedHatDisplay-BlackItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* RedHatDisplay-Bold */
@font-face {
  font-family: "RedHatDisplay-Bold";
  src: url("./fonts/static/RedHatDisplay-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* RedHatDisplay-BoldItalic */
@font-face {
  font-family: "RedHatDisplay-BoldItalic";
  src: url("./fonts/static/RedHatDisplay-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* RedHatDisplay-ExtraBold */
@font-face {
  font-family: "RedHatDisplay-ExtraBold";
  src: url("./fonts/static/RedHatDisplay-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* RedHatDisplay-ExtraBoldItalic */
@font-face {
  font-family: "RedHatDisplay-ExtraBoldItalic";
  src: url("./fonts/static/RedHatDisplay-ExtraBoldItalic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* RedHatDisplay-Italic */
@font-face {
  font-family: "RedHatDisplay-Italic";
  src: url("./fonts/static/RedHatDisplay-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* RedHatDisplay-Light */
@font-face {
  font-family: "RedHatDisplay-Light";
  src: url("./fonts/static/RedHatDisplay-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* RedHatDisplay-LightItalic */
@font-face {
  font-family: "RedHatDisplay-LightItalic";
  src: url("./fonts/static/RedHatDisplay-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* RedHatDisplay-Medium */
@font-face {
  font-family: "RedHatDisplay-Medium";
  src: url("./fonts/static/RedHatDisplay-Medium.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
}

/* RedHatDisplay-MediumItalic */
@font-face {
  font-family: "RedHatDisplay-MediumItalic";
  src: url("./fonts/static/RedHatDisplay-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* RedHatDisplay-Regular */
@font-face {
  font-family: "RedHatDisplay-Regular";
  src: url("./fonts/static/RedHatDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* RedHatDisplay-SemiBold */
@font-face {
  font-family: "RedHatDisplay-SemiBold";
  src: url("./fonts/static/RedHatDisplay-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* RedHatDisplay-SemiBoldItalic */
@font-face {
  font-family: "RedHatDisplay-SemiBoldItalic";
  src: url("./fonts/static/RedHatDisplay-SemiBoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

body {
  font-family: "RedHatDisplay-Regular";
}

.redHatBlack {
  font-family: "RedHatDisplay-Black";
}

.redHatBlackItalic {
  font-family: "RedHatDisplay-BlackItalic";
}

.redHatBold {
  font-family: "RedHatDisplay-Bold";
}

.redHatBoldItalic {
  font-family: "RedHatDisplay-BoldItalic";
}

.redHatExtraBold {
  font-family: "RedHatDisplay-ExtraBold";
}

.redHatExtraBoldItalic {
  font-family: "RedHatDisplay-ExtraBoldItalic";
}

.redHatItalic {
  font-family: "RedHatDisplay-Italic";
}

.redHatLight {
  font-family: "RedHatDisplay-Light";
}

.redHatLightItalic {
  font-family: "RedHatDisplay-LightItalic";
}

.redHatMedium {
  font-family: "RedHatDisplay-Medium";
}

.redHatMediumItalic {
  font-family: "RedHatDisplay-MediumItalic";
}

.redHatRegular {
  font-family: "RedHatDisplay-Regular";
}

.redHatSemiBold {
  font-family: "RedHatDisplay-SemiBold";
}

.redHatSemiBoldItalic {
  font-family: "RedHatDisplay-SemiBoldItalic";
}

.sidebarBtn::after {
  content: "";
  width: 2px;
  height: 20px;
  background-color: #464646;
  position: absolute;
  right: -4px;
}

.loader {
  display: inline-block;
}

.circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px;
  animation: bounceAnimation 1s infinite ease-in-out;
}

.circle1 {
  background-color: #ff659b;
  animation-delay: 0.2s;
}

.circle2 {
  background-color: #52e3ff;
  animation-delay: 0.3s;
}

.circle3 {
  background-color: #67dd86;
  animation-delay: 0.4s;
}

.circle4 {
  background-color: #a3a3a3;
  animation-delay: 0.5s;
}

@keyframes bounceAnimation {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.modalStyle {
  transform: translateY(0) !important;
}
.cursorStyles {
  caret-color: #000000;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-input {
  display: none;
}

.upload-button {
  background-color: #1a73e8;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 8px;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #0f62fe;
}

/* Text styles */
.text {
  position: absolute; /* Set position to absolute */
  top: 50%; /* Align top edge of text to 50% of container height */
  left: 50%; /* Align left edge of text to 50% of container width */
  transform: translate(
    -50%,
    -50%
  ); /* Center text both horizontally and vertically */
  text-align: center;
  /* Center text horizontally */
}
.embla {
  /* max-width: 48rem;
  margin: auto; */
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 440px;
}
.embla__viewport {
  overflow: hidden;
  padding: 0 20px;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  align-items: baseline;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__play {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  color: var(--text-body);
  font-weight: 700;
  font-size: 1.4rem;
  padding: 0 2.4rem;
  min-width: 8.4rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
.gradient-border {
  position: relative;
  text-align: center; /* Optional: centers the text */
}
.gradient-border::after {
  content: "";
  display: block;
  width: 60%; /* Adjust the width as needed */
  height: 2px;
  /* Adjust the height as needed */
  background: linear-gradient(
    to right,
    transparent,
    black,
    transparent
  ); /* Gradient border */
  margin: 0 auto;
  /* Centers the border */
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
}
.neomorphism {
  padding: 20px;
  border-radius: 6px;
  box-shadow: 9.91px 9.91px 15px #d9dade, -9.91px -9.91px 15px #ffffff;
}
#PannaLabs-chat {
  right: 0;
  padding-right: 15px;
  z-index: 100001;
}
/* Overflow slider container */
.slider-container {
  display: flex;
  gap: 8px; /* Adjust gap as needed */
  padding: 4px; /* Reduced padding for a thinner look */
  overflow-x: auto; /* Horizontal scrolling */
  overflow-y: hidden;
  /* Hide vertical scrollbar */
  scrollbar-width: thin; /* For Firefox */
}
/* Custom scrollbar for Webkit browsers */
.slider-container::-webkit-scrollbar {
  height: 8px; /* Adjust height for scrollbar */
}
.slider-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for scrollbar thumb */
}
.slider-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px; /* Rounded corners for scrollbar track */
}
.navigation-header header {
  @apply w-full max-w-full px-5 sm:px-10 lg:px-20; /* Apply Tailwind classes here */
}
